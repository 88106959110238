import React from 'react'
import {Link} from 'react-router-dom'
import data from '../../data/locData';

function ContactUs() {
    const resrData = data.location;
    const listItemStyle = data.settings;
    // TODO: Change API Key
    const apiKey = "AIzaSyBeTyP30NyY5nYm0pIdBWZhoAdW4ctBKvk";
    const mapUrl = "https://www.google.com/maps/embed/v1/place?key=" + apiKey
        + "&q=" + resrData.contact_us.address.replace(/ /g, '+');

    function formatPhoneNumber(phoneNumberString) {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }
    const fphones = resrData.contact_us.phone.map((phone) => formatPhoneNumber(phone));
    const phoneElements = fphones.map((phone) => (
                                        <Link style={{
                                            color: listItemStyle.seconderyColor
                                        }} to="#">{phone}</Link>
                                    )).reduce((prev, curr) => [prev, <br />, curr])

    return (
        <>
            <div id="contactus" className="contactSection newConatctus-wrap displaySection" style={{
                backgroundImage: `url(${resrData.contact_us.background})`
            }}>
                <div className="container">
                    <div className="rowFlex">
                        <div className="address">
                            <h2>Contact Us</h2>
                            <p>Get in touch and let us know how we can help</p>
                            <div className="calling">
                                <div className="iconCallMobile">
                                    <div style={{
                                        background: listItemStyle.seconderyColor,
                                        color: listItemStyle.primaryColor,
                                        fontSize: "1.5em"
                                    }} className="iconCall">
                                        <i className="fa-solid fa-phone"></i>
                                    </div>
                                </div>
                                <div className="numberCall">
                                    <h4 style={{
                                        color: listItemStyle.seconderyColor
                                    }}>Call Us</h4>
                                    {phoneElements}
                                </div>
                            </div>

                            <div className="calling last-cal">
                                <div className="iconCallMobile">
                                    <div style={{
                                        background: listItemStyle.seconderyColor,
                                        color: listItemStyle.primaryColor,
                                        fontSize: "1.5em"
                                    }} className="iconCall">
                                        <i className="fa-regular fa-clock"></i>
                                    </div>
                                </div>
                                <div className="numberCall businessHours">
                                    <h4 style={{
                                        color: listItemStyle.seconderyColor
                                    }}>Business Hours:</h4>
                                    <p>{resrData.contact_us.business_hours}</p>
                                </div>
                            </div>

                        </div>
                        <div className="map">
                            <iframe title={`ContactUs`}
                                    src={mapUrl}
                                    frameBorder="0" style={{width: "500px", height: "500px"}}
                                    allowFullScreen=""
                                    aria-hidden="false"
                                    tabIndex="0"
                            />
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactUs